import axios from 'axios';

const apiDependents = axios.create({
    baseURL: process.env.VUE_APP_API_ROUTE,
    headers: {
      'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_ROUTE} `,
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      common: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  });

  export const setDependentsHeader = (token) => {
    apiDependents.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  export const resetDependentsHeader = () => {
    delete apiDependents.defaults.headers.common.Authorization;
  };


  export default apiDependents;