import Keycloak from 'keycloak-js';
import store from '.././store';

var url = process.env.VUE_APP_KEYCLOAK_URL;

let userInfo = {
    name: '',
    email: '',
    authenticated: false,
    userToken: '',
    keycloak: null,
};

const keycloak = new Keycloak({
  url: url,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
});

export default {
  async initKeycloak(onload) {
    keycloak.onTokenExpired = function () {
      keycloak.updateToken(300).then(async function() {
        store.commit('KEYCLOAK_UPDATE_USER_TOKEN', keycloak.token);
      }).catch(async function () {
        userInfo.userToken = null;
        userInfo.authenticated = false;
        await keycloak.logout();
      });
    };
    if(onload === 'check-sso'){
      await keycloak.init({
        onLoad: onload,
        // silentCheckSsoRedirectUri: `${window.location.origin}/index.html`,
        checkLoginIframe: false,
        pkceMethod: 'S256',
      })
      .then(async function(auth) {
        if(auth){
          userInfo = {
            name: keycloak.idTokenParsed.name,
            email: keycloak.idTokenParsed.email,
            phone: keycloak.idTokenParsed.phone_number?.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3'),
            birthdate: keycloak.idTokenParsed.birth_date,
            cpf: keycloak.idTokenParsed.preferred_username,
            avatar: keycloak.idTokenParsed.avatar,
            authenticated: keycloak.authenticated,
            userToken: keycloak.token,
            keycloak: keycloak,
            userId: keycloak.idTokenParsed.sub,
            gender: keycloak.idTokenParsed.gender ?? null,
          };

          await keycloak.loadUserProfile().then(function(profile) {
            userInfo.zipcode = profile.attributes.zipcode ? profile.attributes.zipcode[0] : '';
            userInfo.files = {
              document_front: keycloak.profile.attributes?.document_front,
              document_verse: keycloak.profile.attributes?.document_verse,
              proof_of_address: keycloak.profile.attributes?.proof_of_address,
              register_document: keycloak.profile.attributes?.register_document
            };
          }).catch(function() {
            alert('Ocorreu um erro ao receber os dados do usuário');
          });
        }else{
          userInfo.authenticated = keycloak.authenticated;
          userInfo.keycloak = keycloak;
        }
      })
      .catch(function() {
        alert('Ocorreu um erro ao inicializar o agendamento');
      });
    }else{
      await keycloak.init({
        checkLoginIframe: false,
        pkceMethod: 'S256',
      })
      .then(async function(auth) {
        if(auth){
          userInfo = {
            name: keycloak.idTokenParsed.name,
            email: keycloak.idTokenParsed.email,
            phone: keycloak.idTokenParsed.phone_number?.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3'),
            birthdate: keycloak.idTokenParsed.birth_date,
            cpf: keycloak.idTokenParsed.preferred_username,
            avatar: keycloak.idTokenParsed.avatar,
            authenticated: keycloak.authenticated,
            userToken: keycloak.token,
            keycloak: keycloak,
            userId: keycloak.idTokenParsed.sub,
            gender: keycloak.idTokenParsed.gender ?? null,
          };

          await keycloak.loadUserProfile().then(function(profile) {
            userInfo.zipcode = profile.attributes.zipcode ? profile.attributes.zipcode[0] : '';
            userInfo.files = {
              document_front: keycloak.profile.attributes?.document_front,
              document_verse: keycloak.profile.attributes?.document_verse,
              proof_of_address: keycloak.profile.attributes?.proof_of_address,
              register_document: keycloak.profile.attributes?.register_document
            };
          }).catch(function() {
            alert('Ocorreu um erro ao receber os dados do usuário');
          });
        }else{
          userInfo.authenticated = keycloak.authenticated;
          userInfo.keycloak = keycloak;
        }
      })
      .catch(function() {
        alert('Ocorreu um erro ao inicializar o agendamento');
      });
    }

    return userInfo;
  },
  async updateToken(){
    keycloak.onTokenExpired = function () {
      keycloak.updateToken(300).then(async function() {
        store.commit('KEYCLOAK_UPDATE_USER_TOKEN', keycloak.token);
      }).catch(async function () {
        userInfo.userToken = null;
        userInfo.authenticated = false;
        await keycloak.logout();
      });
    };
  },

  async autologinKeycloak(token, refreshToken, clientId){
    const autoLoginkeycloak = new Keycloak({
      url: process.env.VUE_APP_KEYCLOAK_URL,
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      clientId: clientId,
    });

    await autoLoginkeycloak.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
      token: token,
      refreshToken: refreshToken,
    })
    .then(async function(authenticated) {
      if(authenticated){
        userInfo = {
          name: autoLoginkeycloak.tokenParsed.name,
          email: autoLoginkeycloak.tokenParsed.email,
          phone: autoLoginkeycloak.tokenParsed.phone_number?.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3'),
          birthdate: autoLoginkeycloak.tokenParsed.birth_date,
          cpf: autoLoginkeycloak.tokenParsed.preferred_username,
          avatar: autoLoginkeycloak.tokenParsed.avatar,
          authenticated: autoLoginkeycloak.authenticated,
          userToken: autoLoginkeycloak.token,
          keycloak: autoLoginkeycloak,
          userId: autoLoginkeycloak.tokenParsed.sub,
          gender: autoLoginkeycloak.tokenParsed.gender ?? null,
        };

        await autoLoginkeycloak.loadUserProfile().then(function(profile) {
          userInfo.zipcode = profile.attributes.zipcode ? profile.attributes.zipcode[0] : '';
          userInfo.files = {
            document_front: autoLoginkeycloak.profile.attributes?.document_front,
            document_verse: autoLoginkeycloak.profile.attributes?.document_verse,
            proof_of_address: autoLoginkeycloak.profile.attributes?.proof_of_address,
            register_document: autoLoginkeycloak.profile.attributes?.register_document
          };
        }).catch(function() {
          alert('Ocorreu um erro ao receber os dados do usuário');
        });
      }else{
        userInfo.authenticated = autoLoginkeycloak.authenticated;
        userInfo.keycloak = autoLoginkeycloak;
      }
    })
    .catch(function(e) {
      alert(`failed to initialize ${e}`);
    });

    return userInfo;
  }
};
