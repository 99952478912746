<template>
  <!-- eslint-disable  max-len -->
  <div id="app">
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>
<script>
// eslint-disable max-len
import { hotjarMixin } from '@/mixins/hotjarMixin';

export default {
  name: 'App',


  mixins: [ hotjarMixin ],

  data: () => ({
    //
  }),
  computed: {
    privacyPoliciesURL() {
      switch(process.env.VUE_APP_KEYCLOAK_REALM) {
        case 'Uppersoft':
          return 'https://upcities.app/politica-privacidade-pmvv';
        case 'pmvv':
          return 'https://upcities.app/politica-privacidade-pmvv';
        case 'pmcariacica':
          return 'https://upcities.app/politica-privacidade-cariacica';
        case 'pmci':
          return 'https://upcities.app/politica-privacidade-cachoeiro-itapemirim';
        case 'aracruz':
          return 'https://upcities.app/politica-privacidade-aracruz';
        default :
        return '#';
      }
    },
    processRealm() {
      return process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft' ? 'upcities' : process.env.VUE_APP_KEYCLOAK_REALM;
    },
  },
  metaInfo() {

    return {
      titleTemplate: `%s | Saúde  ${this.$t('SUBHEADER.city')}`,

      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Sua Unidade de Saúde Online. Agende consultas sem enfrentar filas.',
          },

          // OpenGraph
          {
            vmid: 'og:title',
            property: 'og:title',
            content: `Saúde  ${this.$t('SUBHEADER.city')}`,
          },
          {
            vmid: 'og:site_name',
            property: 'og:site_name',
            content: `Saúde  ${this.$t('SUBHEADER.city')}`,
          },
          {
            vmid: 'og:type',
            property: 'og:type',
            content: 'website',
          },
          {
            vmid: 'og:url',
            property: 'og:url',
            content: `${window.location.origin}`,
          },
          {
            vmid: 'og:image',
            property: 'og:image',
            content: require(`@/assets/theme/${this.processRealm}/icons/og/meta.jpg`),
          },
          {
            vmid: 'og:description',
            property: 'og:description',
            content: 'Sua Unidade de Saúde Online. Agende consultas sem enfrentar filas.',
          },

          // Twitter
          {
            vmid: 'twitter:card',
            name: 'twitter:card',
            content: 'Sua Unidade de Saúde Online. Agende consultas sem enfrentar filas.',
          },
          {
            vmid: 'twitter:site',
            name: 'twitter:site',
            content: `${window.location.origin}`,
          },
          {
            vmid: 'twitter:title',
            name: 'twitter:title',
            content: `Saúde  ${this.$t('SUBHEADER.city')}`,
          },
          {
            vmid: 'twitter:description',
            name: 'twitter:description',
            content: 'Sua Unidade de Saúde Online. Agende consultas sem enfrentar filas.',
          },
           {
            vmid: 'twitter:image',
            name: 'twitter:image',
            content: require(`@/assets/theme/${this.processRealm}/icons/og/meta.jpg`),
          },
          {
            vmid: 'twitter:image:src',
            name: 'twitter:image:src',
            content: require(`@/assets/theme/${this.processRealm}/icons/og/meta.jpg`),
          },

          {
            vmid: 'name',
            itemprop: 'name',
            content: `Saúde  ${this.$t('SUBHEADER.city')}`,
          },
          {
            vmid: 'description',
            itemprop: 'description',
            content: 'Sua Unidade de Saúde Online. Agende consultas sem enfrentar filas.',
          },
          {
            vmid: 'image',
            itemprop: 'image',
            content: require(`@/assets/theme/${this.processRealm}/icons/og/meta.jpg`),
          },
        ],

        link: [
          {
            vmid: 'api',
            rel: 'preconnect',
            href: `${process.env.VUE_APP_API_ROUTE}`,
          },
          {
            vmid: 'conta',
            rel: 'preconnect',
            href: `${process.env.VUE_APP_KEYCLOAK_URL}`,
          },
          {
            vmid: 'minha-conta',
            rel: 'preconnect',
            href: `${process.env.VUE_APP_MINHACONTA_ROUTE}`,
          },
          {
            vmid: 'canonical',
            rel: 'canonical',
            href: `${window.location.origin}`,
          },
          {
            vmid: 'favicon',
            rel: 'icon',
            href: require(`@/assets/theme/${this.processRealm}/icons/favicon/favicon.png`),
          },
        ],
      };
  },
  mounted() {
    setTimeout(() => {
      let url = document.getElementById('policies-link');

      url === null ? '' :  url.href = this.privacyPoliciesURL;
    }, 2500);

    this.includeClarity();
    this.includeAnalytics();

  },
  methods: {
    includeClarity(){
      let importScript = document.createElement('script');

      importScript.type = 'text/javascript';

      if(process.env.VUE_APP_CLARITY_TOKEN){
        importScript.onload = (function(c,l,a,r,i,t,y){

          // eslint-disable-next-line no-param-reassign
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments);};
          // eslint-disable-next-line no-param-reassign
          t=l.createElement(r);t.async=1;t.src=`https://www.clarity.ms/tag/${i}`;
          // eslint-disable-next-line no-param-reassign
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, 'clarity', 'script', process.env.VUE_APP_CLARITY_TOKEN);
      }
    },

    includeAnalytics() {
      let importNode = document.createElement('script');
      let importScript = document.createElement('script');

      importNode.type = 'text/javascript';
      importScript.type = 'text/javascript';

      importNode.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_ANALYTICS_GTAG}`;
      importNode.async = true;

      importScript.onload = function() {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', process.env.VUE_APP_ANALYTICS_GTAG);
      };
    }
  }
};
</script>

<style lang="scss">
  // -- Commented while accessibility bar is hidden --
  // [vw] [vw-access-button] img.access-button,
  // [vw] [vw-access-button] img.pop-up {
  //   display: none !important;
  // }

  /**
   *
   * Normalize from Tailwind
   * https://unpkg.com/tailwindcss@1.1.4/dist/base.css
   *
   */
  @import "@/assets/sass/base/_normalize.scss";

  /**
   *
   * Base
   *
   */
  @import "@/assets/sass/base/_container.scss";
  @import "@/assets/sass/base/_fonts.scss";
  @import "@/assets/sass/base/_transitions.scss";
  @import "@/assets/sass/base/_variables.scss";

  /**
   *
   * Project
   *
   */
  @import "@/assets/sass/project/_buttons.scss";
  @import "@/assets/sass/project/_form.scss";
  @import "@/assets/sass/project/_typography.scss";
  @import "@/assets/sass/project/_datepicker.scss";
  @import "@/assets/sass/project/_radio.scss";

  html {
    scroll-behavior: smooth;

    body {
      font-family: $font-sans;
      color: $black;
      font-weight: $font-regular;
      font-size: $text-base;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $gray-bg;
      overflow-x: hidden;

      & div,
      & article,
      & div ::after {
        transition: background-color 0.2s ease-out, color 0.2s ease-out, border-color 0.2s ease-out;
      }

      //responsive svg
      svg {
        max-width: 100%;
        height: auto;
      }

      #app {
        min-height: 100vh;

        // Changing modal background
        .loading-page {
          background-color: rgba(darken(saturate($primary, 100%), 15%), 0.75);
          background-blend-mode: luminosity;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center right;
          min-height: 100vh;
          min-width: 100vw;
          display: flex;
          justify-content: center;
          padding: 7.8125rem 7.1875rem;//125px 115px

          @include screen(phone-only) {
            padding: 2rem;
          }

          .message {
            height: 100%;
            width: auto;
          }

          svg {
            color: $white;
            fill: $white;
          }
        }
      }

      &.blocked {
        overflow: hidden;
      }

      &.fixed {
        position: fixed;
      }
    }
  }

  .modal-override {
    border-radius: 0.5rem !important;
  }

  .grecaptcha-badge,
  .access-button {
    @media print {
      display: none !important;
    }
  }
</style>
