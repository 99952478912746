import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ROUTE,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_ROUTE} `,
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
});

export const surveyApi = axios.create({
  baseURL: process.env.VUE_APP_SURVEY_API_ROUTE,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
});

export const setAuthHeader = (token) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  surveyApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const resetAuthHeader = () => {
  delete api.defaults.headers.common.Authorization;
  delete surveyApi.defaults.headers.common.Authorization;
};

export default api;
