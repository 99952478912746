module.exports = {
  CONTENT: {
    SUBHEADER: {
      city: 'Cachoeiro de Itapemirim',
    },
    HEADER: {
      title: 'Bem vindo a sua Unidade de Saúde Online',
      titleLogged: 'Olá, ',
      subtitle: 'Agende os serviços disponíveis em uma Unidade de Saúde da Prefeitura de Cachoeiro de Itapemirim. Siga o passo a passo e leia com atenção todas as informações.',
    },
    STEPPER: {
      ONE: {
        title: 'Usuário',
      description: 'Selecione para quem será realizado o agendamento',
      },
      TWO: {
        title: 'Categorias',
        description: 'Selecione a categoria na qual deseja marcar um serviço.',
      },
      THREE: {
        title: 'Serviços',
        description: 'Selecione o serviço e em seguida a unidade de atendimento.',
      },
      FOUR: {
        title: 'Dados',
        description: 'Insira os dados necessários.',
      },
      FIVE: {
        title: 'Confirmação',
        description: 'Confira seus dados e agende o seu atendimento',
      },
    },
    HOME: {
      BANNER: {
        title: 'SUA UNIDADE DE SAÚDE ONLINE',
        titleLogged: 'SUA UNIDADE DE SAÚDE ONLINE',
        description: 'Faça seu agendamento sem enfrentar filas',
        descriptionLogged: 'Faça seu agendamento sem enfrentar filas',
      },
      DISTRICTBANNER: {
        title: 'Consultar unidades disponíveis para atendimento',
        subtitle: 'Encontre informações de localização e contato das Unidades de Saúde do seu município.',
        titleMobile: 'Unidades de Saúde',
        subtitleMobile: 'Encontre informações de localização e contato das Unidades de Saúde do seu município.',
        button: 'Ver lista'
      },
      HIGHLIGHTS: {
        FIRST: {
          title: 'Vinculação online',
          description: 'Envie uma solicitação online para vincular você ou seu dependente a uma Unidade.',
          href: '/vinculacao-online',
        },
        SECOND: {
          title: 'Cadastro de dependentes',
          description: 'Realize agendamentos médicos para terceiros através do cadastro de dependentes.',
          href: `${process.env.VUE_APP_MINHACONTA_ROUTE}/dependent`,
        },
        THIRD: {
          title: 'Mantenha seu cadastro atualizado',
          description: 'Atualize seu cadastro para ter acesso aos serviços disponibilizados pela Unidade.',
          href: `${process.env.VUE_APP_MINHACONTA_ROUTE}/profile/information`
        },
      },
      MODAL: {
        description: 'Para utilizar um serviço nas unidades da Prefeitura de Cachoeiro de Itapemirim, é necessário ser morador da cidade e completar a etapa abaixo:'
      },
      OTHERS: {
        LINKS: {
          googlePlay: 'https://prml.me/JIVY',
          appleStore: 'https://prml.me/JJs7',
        }
      },
    },
    GENERALINFO: {
      FIRST: {
        title: 'Antes do atendimento:',
        description: 'Procure um lugar sem barulho, com boa iluminação e onde não será interrompido;//Verifique a sua conexão de internet;//Confira se a câmera e o microfone do dispositivo estão funcionando;//Dê preferência para o uso de fone de ouvido durante a chamada por vídeo;',
      },
      SECOND: {
        title: 'No momento do atendimento:',
        description: 'A sala ficará disponível para acesso 15min antes do horário da consulta;//Você poderá acessar pelo link enviado via e-mail ou em \'Meus agendamentos\';',
      },
    },
    FOOTER: {
      ABOUT: {
        first: 'Vinculação online',
        second: 'Lista de Unidades',
        third: 'Dúvidas',
      },
      MENU: {
        first: 'Meus agendamentos',
        second: 'Agendar',
        third: '',
      },
      CONTACT: {
        title: 'Em caso de dúvidas:',
        email: 'semus@cachoeiro.es.gov.br',
        tel: '(28) 3155-5252 / (28) 3155-5226',
        address: ''
      },
      ICONS: {
        LINKS: {
          facebook: 'https://www.facebook.com/prefcachoeiro/',
          linkedin: 'https://www.linkedin.com/company/prefeitura-municipal-de-cachoeiro-de-itapemirim/',
          instagram: 'https://www.instagram.com/prefcachoeiro/',
          youtube: 'https://www.youtube.com/user/semcoscachoeiro',
        }
      }
    },
    FAQ: {
      faqItems: [
        {
          id: 0,
          question: 'Preciso estar vinculado a uma Unidade de Saúde para fazer um agendamento online?',
          answer: 'As Unidades de Saúde contam com estrutura e serviços adequados para atender a demanda das regiões em que estão localizadas. Por isso, é importante que o seu cadastro seja vinculado a Unidade de Saúde mais próxima da sua casa, para que os atendimentos sejam feitos de forma adequada, de acordo com a necessidade da sua localização. A vinculação é automática e leva apenas algumas horas após seu cadastro. Mas se no seu caso não for realizada automaticamente, ela pode ser feita levando documento com foto e comprovante de residência na Unidade de Saúde mais próxima de você.'
        },
        {
          id: 1,
          question: 'Posso agendar para outras pessoas da minha família usando meu cadastro?',
          answer: 'Sim! Com o cadastro de dependentes é possível agendar para outras pessoas, com ou sem grau de parentesco. Após o cadastro o dependente deverá ser vinculado a uma Unidade de Saúde, como descrito aqui. Apenas pessoas que não possuem cadastro poderão ser adicionadas como dependentes. Cadastre dependentes clicando aqui',
          link: [{
            text: 'descrito aqui',
            ref: 'UnitiesList',
            absoluteURL: false,
          },
          {
            text: 'clicando aqui',
            ref: `${process.env.VUE_APP_MINHACONTA_ROUTE}/dependent`,
            absoluteURL: true,
          }]
        },
        {
          id: 2,
          question: 'Preciso alterar a data do meu agendamento. O que fazer?',
          answer: 'Não é possível alterar data ou horário do seu agendamento. Caso não possa comparecer no dia/horário agendado, basta cancelar o agendamento e realizar um novo. Para cancelar um agendamento clique aqui.',
          link: [{
            text: 'clique aqui',
            ref: 'ConsultaAgendamento',
            absoluteURL: false,
          }]
        },
        {
          id: 3,
          question: 'Tentei realizar um agendamento e não encontro datas disponíveis. O que fazer?',
          answer: 'As vagas são ofertadas de acordo com a agenda da Unidade de Saúde. Fique de olho no sistema ou procure a sua Unidade de Saúde para saber mais informações.'
        },
        {
          id: 4,
          question: 'Quais serviços encontro na Unidade de Saúde online?',
          answer: 'Os principais serviços oferecidos pelas Unidades de Saúdes são consultas médicas, vacinas, coleta de exames laboratoriais, tratamento odontológico, encaminhamentos para especialidades e fornecimento de medicação básica, além de ações de prevenção e tratamento relacionadas à saúde da mulher, criança, saúde mental, planejamento familiar, prevenção ao câncer, pré-natal e cuidado de doenças crônicas.'
        },
        {
          id: 5,
          question: 'Como saber se meu agendamento foi efetivado?',
          answer: 'Clique em "Meus Agendamentos" na tela inicial e em "próximos agendamentos" verifique se seu agendamento consta na listagem. Você também receberá um e-mail com a confirmação.'
        },
        {
          id: 6,
          question: 'Não consigo encontrar a vacina que preciso. O que fazer?',
          answer: 'A vacina que você procura pode ser uma vacina de campanha ou não estar disponível para agendamento online. Aqui você poderá encontrar apenas serviços que são oferecidos por Unidades Básicas de Saúde. O agendamento das vacinas de campanha (como por exemplo a vacina para Covid-19) podem ser feitas clicando aqui, caso não encontre a vacina desejada vá a sua Unidade de Saúde para obter mais informações.',
          link: [{
            text: 'clicando aqui',
            ref: 'Appointment',
            absoluteURL: false,
          }]
        }
      ],
    },
    OTHER: {
      multiAgendamento: '',
    },
    UNITIES: {
      BANNER: {
        description: 'É necessário estar vinculado a uma Unidade de Saúde para acessar os serviços online.<br> Aguarde algumas horas após se cadastrar para ser vinculado automaticamente. Após esse prazo, em caso de não constar nenhuma vinculação, recomendamos que vá a uma Unidade de Saúde mais próxima, levando documento com foto e comprovante de residência.',
        default: [
          {
            data_publicacao: null,
            link: null,
            titulo: 'A saúde da população é nossa prioridade.',
            subtitulo: 'A Prefeitura de Cachoeiro de Itapemirim está comprometida em oferecer serviços de saúde de qualidade e acessíveis a todos os cidadãos.',
            icone: 'https://firebasestorage.googleapis.com/v0/b/upcities-uppersoft.appspot.com/o/icons%2Fsyringe-solid.svg?alt=media',
            cor: 'escuro',
            file: null
          }
        ]
      }
    }
  }
};