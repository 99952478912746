import { surveyApi } from '@/config/axios/index.js';

export default {
  getSurvey() {
    return surveyApi({
      method: 'GET',
      url: `/api/v1/survey/${process.env.VUE_APP_SURVEY_ID}`,
      data: null,
    });
  },

  postSurvey(data) {
    return surveyApi({
      method: 'POST',
      url: `/api/v1/survey/${process.env.VUE_APP_SURVEY_ID}/answers`,
      data,
    });
  }
};
