import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.component('ModalElement', () => import('@/components/common/ModalElement.vue'));
Vue.component('Loading', () => import('@/components/common/Loading.vue'));
Vue.component('Icon', () => import('@/components/base/svg/SVG.vue'));
Vue.component('VSelect', () => import('vue-select'));
Vue.component('ButtonComponent', () => import('@/components/common/ButtonComponent.vue'));
Vue.component('GoogleIcon', () => import('@/components/common/GoogleIcon.vue'));
Vue.component('AvatarPlaceholder', () => import('vue-avatar'));
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
