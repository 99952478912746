module.exports = {
  CONTENT: {
    SUBHEADER: {
      city: 'Vila Velha',
    },
    HEADER: {
      title: 'Bem vindo a sua Unidade de Saúde Online',
      titleLogged: 'Olá, ',
      subtitle: 'Escolha o serviço desejado e agende um horário de atendimento em uma das unidades da Prefeitura de Vila Velha. Siga o passo a passo e leia com atenção todas as informações.',
    },
    STEPPER: {
      ONE: {
        title: 'Usuário',
        description: 'Selecione para quem será realizado o agendamento',
      },
      TWO: {
        title: 'Categorias',
        description: 'Selecione a categoria na qual deseja marcar um serviço.',
      },
      THREE: {
        title: 'Serviços',
        description: 'Selecione o serviço e em seguida a unidade de atendimento.',
      },
      FOUR: {
        title: 'Dados',
        description: 'Insira os dados necessários.',
      },
      FIVE: {
        title: 'Confirmação',
        description: 'Confira seus dados e agende o seu atendimento',
      },
    },
    HOME: {
      BANNER: {
        title: 'SUA UNIDADE DE SAÚDE ONLINE',
        titleLogged: 'SUA UNIDADE DE SAÚDE ONLINE',
        description: 'Faça seu agendamento sem enfrentar filas',
        descriptionLogged: 'Faça seu agendamento sem enfrentar filas',
      },
      DISTRICTBANNER: {
        title: 'Consultar bairros disponíveis para atendimento',
        subtitle: 'Cada Unidade de Saúde tem uma lista de bairros atendidos, consulte a lista de unidades e bairros atendidos no botão “ver lista"',
        titleMobile: 'Bairros atendidos',
        subtitleMobile: 'Cada Unidade de Saúde tem uma lista de bairros atendidos. Clique e consulte.',
        button: 'Ver lista'
      },
      HIGHLIGHTS: {
        FIRST: {
          title: 'Vinculação online',
          description: 'Envie uma solicitação online para vincular você ou seu dependente a uma Unidade.',
          href: '/vinculacao-online',
        },
        SECOND: {
          title: 'Cadastro de dependentes',
          description: 'Realize agendamentos médicos para terceiros através do cadastro de dependentes.',
          href: `${process.env.VUE_APP_MINHACONTA_ROUTE}/dependent`,
        },
        THIRD: {
          title: 'Mantenha seu cadastro atualizado',
          description: 'Atualize seu cadastro para ter acesso aos serviços disponibilizados pela Unidade.',
          href: `${process.env.VUE_APP_MINHACONTA_ROUTE}/profile/information`
        },
      },
      MODAL: {
        description: 'Para utilizar um serviço nas unidades da Prefeitura de Vila Velha, é necessário ser morador da cidade e completar a etapa abaixo:'
      },
      OTHERS: {
        LINKS: {
          googlePlay: 'https://prml.me/appandroidvilavelha',
          appleStore: 'https://prml.me/appiosvilavelha',
        }
      },
    },
    FOOTER: {
      ABOUT: {
        first: 'Vinculação online',
        second: 'Lista de Unidades',
        third: 'Dúvidas',
      },
      MENU: {
        first: 'Meus agendamentos',
        second: 'Agendar',
        third: 'Teste Covid-19',
      },
      CONTACT: {
        title: 'Em caso de dúvidas:',
        email: '',
        tel: '',
        address: ''
      },
      ICONS: {
        LINKS: {
          facebook: 'https://www.facebook.com/prefvilavelha',
          linkedin: 'https://www.linkedin.com/company/prefvilavelha',
          instagram: 'https://www.instagram.com/prefvilavelha',
          youtube: 'https://www.youtube.com/c/PrefVilaVelha',
        }
      }
    },
    FAQ: {
      faqItems: [
        {
          id: 0,
          question: 'Preciso estar vinculado a uma Unidade de Saúde para fazer um agendamento online?',
          answer: 'Sim, após realizar o cadastro no site da PMVV, compareça a unidade de saúde referência da sua residência para fazer a vinculação do cadastro, e após a vinculação o acesso ao sistema de agendamento online será liberado.'
        },
        {
          id: 1,
          question: 'Posso agendar para outras pessoas da minha família usando meu cadastro?',
          answer: 'Sim! Com o cadastro de dependentes é possível agendar para outras pessoas, com ou sem grau de parentesco. Após o cadastro o dependente deverá ser vinculado a uma Unidade de Saúde, como descrito aqui. Apenas pessoas que não possuem cadastro poderão ser adicionadas como dependentes. Cadastre dependentes clicando aqui',
          link: [{
            text: 'descrito aqui',
            ref: 'UnitiesList',
            absoluteURL: false,
          },
          {
            text: 'clicando aqui',
            ref: `${process.env.VUE_APP_MINHACONTA_ROUTE}/dependent`,
            absoluteURL: true,
          }]
        },
        {
          id: 2,
          question: 'Preciso alterar a data do meu agendamento. O que fazer?',
          answer: 'Caso não possa comparecer no dia/horário agendado, basta cancelar o agendamento e realizar um novo (verificar a disponibilidade de vaga). Para cancelar um agendamento clique aqui.',
          link: [{
            text: 'clique aqui',
            ref: 'ConsultaAgendamento',
            absoluteURL: false
          }]
        },
        {
          id: 3,
          question: 'Tentei realizar um agendamento e não encontro datas disponíveis. O que fazer?',
          answer: 'As vagas são ofertadas de acordo com a agenda da Unidade de Saúde e serão disponibilizadas semanalmente. Tendo a necessidade e não conseguindo pelo agendamento online, a equipe multidisciplinar estará preparada para atendê-lo. Em casos de urgência ou emergência, procure o Pronto atendimento mais próximo.'
        },
        {
          id: 4,
          question: 'Quais serviços encontro na Unidade de Saúde online?',
          answer: 'Serão disponibilizadas vagas para consulta com Medico clinico geral.'
        },
        {
          id: 5,
          question: 'Como saber se meu agendamento foi efetivado?',
          answer: 'Clique em "Meus Agendamentos" na tela inicial e em "próximos agendamentos" verifique se seu agendamento consta na listagem. Você também receberá um e-mail com a confirmação.'
        },
        {
          id: 6,
          question: 'Não consigo encontrar a vacina que preciso. O que fazer?',
          answer: 'Entre em contato telefônico com sua unidade de saúde ou compareça a unidade para maiores informações.'
        }
      ],
    },
    OTHER: {
      multiAgendamento: 'https://agenda.vilavelha.es.gov.br/agendamento',
    },
    UNITIES: {
      BANNER: {
        description: 'Informamos que a liberação do cadastro é feita na unidade de referência do seu domicilio (se não souber qual é sua unidade, procure a mais próxima da sua moradia para orientação). Ao precisar de algum serviço de saúde existente nas unidades, aproveite a oportunidade e valide o acesso para marcação online. Não se esqueça de levar comprovante de residência e documentos pessoais.',
        default: [
          {
            data_publicacao: null,
            link: null,
            titulo: 'A saúde da população é nossa prioridade.',
            subtitulo: 'A Prefeitura de Vila Velha está comprometida em oferecer serviços de saúde de qualidade e acessíveis a todos os cidadãos.',
            icone: 'https://firebasestorage.googleapis.com/v0/b/upcities-uppersoft.appspot.com/o/icons%2Fsyringe-solid.svg?alt=media',
            cor: 'escuro',
            file: null
          }
        ]
      }
    }
  }
};