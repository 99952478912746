import api from '@/config/axios/index';

export default {
  getUnity() {
    return api({
      method: 'GET',
      data: null,
      url: '/api/v1/cidadao/unidades',
    });
  },
  getUnitiesList() {
    return api({
      method: 'GET',
      url: 'api/v1/unidades',
      data: null,
    });
  },
  getBanner() {
    return api({
      method: 'GET',
      data: null,
      url: 'api/v1/banner'
    });
  },
  getShortcuts() {
    return api({
      method: 'GET',
      data: null,
      url: 'api/v1/atalho'
    });
  }
};